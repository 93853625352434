import {
  I_CallToAction,
  I_CallToActionConfigGlobal,
} from '@containers/Home/types';
import dynamic from 'next/dynamic';
import { memo, useState } from 'react';
import { CallToActionsWrapper } from './styled';

const Item = dynamic(() => import('./Item'));

interface Props {
  listCallToAction?: I_CallToAction[];
  callToActionConfig?: I_CallToActionConfigGlobal;
}

const CallToAction = memo(
  ({ listCallToAction = [], callToActionConfig }: Props) => {
    const [isShowPanel, setIsShowPanel] = useState(false);

    return (
      <CallToActionsWrapper
        offsetLeft={callToActionConfig?.offsetLeft}
        offsetRight={callToActionConfig?.offsetRight}
        offsetBottom={callToActionConfig?.offsetBottom}
        className={`${
          callToActionConfig?.layout === 'bubble' ? '' : '--layoutList'
        } ${callToActionConfig?.position === 'left' ? '--left' : '--right'} ${
          isShowPanel ? 'active' : ''
        }`}
      >
        <div
          className="--toggler"
          onClick={() => setIsShowPanel(prev => !prev)}
        >
          <i aria-hidden className="fal fa-ellipsis-v" />
          <i aria-hidden className="fal fa-times" />
        </div>
        <div className="--panel">
          {listCallToAction.map(item => (
            <Item key={item.id} item={item} />
          ))}
        </div>
      </CallToActionsWrapper>
    );
  },
);

export default CallToAction;
